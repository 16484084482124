.card {
    border: 0 !important;
    border-radius: 20px !important;
    height: 27rem !important;
    .card-img-top {
        border-radius: 20px;
    }
}

.archived {
    .card-header {
        position: relative;
        background-size: contain;
        &::after {
            display: block;
            content: "Annonce archivée";
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 20px;
            padding: 5px;
            text-align: center;
            color: $dark;
            background-color: $orange;
            width: 100%;
        }
    }
}

.blog .card, .annonces .card, .compte .card, .homepage .card {
    background-size: cover;
    position: relative;
    [class*="knowMore"]{
        background: rgba(0, 0, 0, 0.363);
        transition: background-color 1s ease-in-out;
        position: absolute;
        top: 18.9rem;
        right: 5px;
        padding: 5px 15px;
        font-size: 12px;
        i {
            transition: rotate 1s ease-in-out;
        }
        &[class*="--type"]{
            left: 5px;
            right: auto;
            @media screen and (max-width: 860px) {
                bottom: 0;
                left: 0;
                border-radius: 0 0 0 20px;
                background: rgba(0, 0, 0, 0.363);
            }
        }
        @media screen and (max-width: 860px) {
            bottom: 2px;
            top:auto;
            right: 0;
            background: none;
        }
    }
    &:hover {
        .knowMore {
            background: rgba($blue, 0.363);
            transition: background-color 0.7s ease-in-out;
            i {
                animation: rotate 1s linear infinite;
                transition: rotate 1s ease-in-out;
            }
            @media screen and (max-width: 860px) {
                background: none;
            }
        }
        &::before {
            opacity: 1;
            transition: opacity 0.4s ease-in-out;
        }
    }
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        background-color: $dark;
        transition: opacity 1s ease-in-out;
        opacity: 0.7;
        height: 100%;
        width: 100%;
        z-index: 0;
        border-radius: 20px !important;
    }
    .card-body {
        padding: 15px 10px 0 10px;
    }
    .card-body h3 {
        font-size: 16px;
        color: white;
        background: none !important;
        -webkit-background-clip: none;
        -webkit-text-fill-color: white;
        margin-bottom: 0;
        @media screen and (max-width: 860px) {
            font-size: 17px;
        }
    }
    .card-body, p {
        z-index: 10;
        color: rgba(255, 255, 255, 0.591);
        text-align: center;
        font-size: 15px;
        margin-bottom: 5px;
        @media screen and (max-width: 860px) {
            font-size: 13px;
        }
    }

    hr {
        border: 1px solid $orange;
        width: 100%;
        z-index: 10;
    }

    .btn {
        z-index: 10;
    }
}

.blog .card {
    height: auto !important;
    overflow: hidden;
    .knowMore {
        bottom: 0;
        top: auto;
    }
}

.annonces .card, .compte .card, .homepage .card {
    @media screen and (max-width: 860px) {
        height: 16rem !important;
        display: flex !important;
        flex-direction: row !important;
    }

    &::before {
        opacity: 0.85;
    }
    .card-header {
        position: relative;
        height: 21rem;
        width: 100%;
        margin: 0;
        padding: 0;
        z-index: 1; /* Assurez-vous que le z-index est défini sur un niveau approprié */
        border-bottom: 0px !important;
        border-radius: 20px 20px 0 0;
        filter: drop-shadow(6mm 6mm 8mm rgba(48, 47, 47, 0.484));
        overflow: hidden;
    }
    .card-header img {
        width: 100%;
        height: 100%;
        visibility: hidden;
        transition: all 0.5s;
    }
    
    @media screen and (max-width: 860px) {
        .card-header {
            height: 16rem;
            border-radius: 20px 0 0 20px;
            width: 60%;
        }
    }
    
    @media screen and (max-width: 350px) {
        .card-header {
            width: 50%;
        }
    }
    
    .card-body {
        @media screen and (max-width: 860px) {
            display: flex;
            justify-content: center;
            align-self: center;
            align-items: center;
            flex-direction: column;
            width: 40%;
            overflow: hidden;
        }  
        @media screen and (max-width: 350px) {
            width: 50%;
        }  
    }
}

.card.archived {
    &::before {
        opacity: 0.97;
        @media screen and (max-width: 860px) {
            opacity: 0.99;
        }
    }
}

.card.boosted.archived {
    &::before {
        background-color: $dark;
        opacity: 0.95;
        @media screen and (max-width: 860px) {
            opacity: 0.99;
        }
    }
}

.card.boosted {
    position: relative;
    &::before {
        background-color: $boost;
        transition: background-color 1s ease-in-out;
        @media screen and (max-width: 860px) {
            opacity: 0.99;
        }
    }
    &:hover {
        &::before {
            background-color: $dark;
            opacity: 0.8;
            transition: background-color 0.4s ease-in-out;
        }
    }
    .card-body, p {
        &::before {
            display: block;
            font-family: "Font Awesome 5 Free"; 
            font-weight: 900; 
            font-size: 15px;
            content: "\f005";
            position: absolute;
            top: 3px;
            right: 3px;
            padding: 5px 12px;
            border-radius: 20px;
            color: $orange;
            background-color: rgba($dark, 0.8);
            box-shadow: 0px 0px 17px -8px $dark;
        }
    }
}

.adv {
    .card {
        border-radius: 20px !important;
        @media screen and (max-width: 860px) {
            height: 100% !important;
        }
        .card-header {
            position: absolute;
            height: 100%;
            border-radius: 20px;
            opacity: 0.5;
            @media screen and (max-width: 860px) {
                width: 100%;
                border-radius: 20px;
            }
        }
        .card-text {
            text-align: left;
        }

        .text-decoration {
            padding: 2px 2px 5px 2px; /* Ajustez la valeur du padding selon vos besoins */
            background-color: #00000028;
            color: $orange;
            font-weight: 600;
            border-radius: 5px;
        }
        hr {
            display: block;
            height: 1px;
            border: 0;
            border-top: 1px solid white;
            margin: 1em 0;
            padding: 0;
        }
        .card-body {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            @media screen and (max-width: 860px) {
                position: static;
                transform: none;
                padding: 25px;
            }
            h5 {
                font-size: 30px;
                font-weight: 600;
                background: radial-gradient(circle at center center, rgb(255, 255, 255) 0%, rgb(234 237 255 / 85%) 56%, rgba(255, 255, 255, 0.838) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}