.breadcrumb {
    background-color: rgba(white, 0.3) !important;
    font-weight: 500;
    padding-left: 30px !important;
    padding-right: 30px !important;
    border-radius: 20px !important;
    a {
        color : $dark;
        &:hover {
            color: $blue;
            text-decoration: none;
        }
    }
    .breadcrumb-item.active{ 
        color: $success;
    }
}

.annonce-boosted {
    .breadcrumb {
        background-color: $dark !important;
        a {
            color : white;
            &:hover {
                color: $blue;
                text-decoration: none;
            }
        }
        .breadcrumb-item.active{ 
            color: $orange;
        }
    }
}