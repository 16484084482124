.nav-pills {
    .nav-item {
        .nav-link.active {
            i {
                color: white;
            }
        }
        background-color: $dark;
        i {
            color: $orange;
        }
        a {
            color: white;
            font-weight: 500;
            transition: background-color 0.5s linear;
            &:hover {
                background-color: $blue;
                transition: background-color 1s linear;
            }
        }

    }
}

ul.form {
    position: static;
    top: 115px;
    background:#fff;
    // width:250px;
    margin:auto;
    padding:0;
    list-style: none;
    overflow:hidden;
    
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;	
    
    -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    box-shadow:  1px 1px 10px rgba(0, 0, 0, 0.1);	

    @media screen and (max-width: 990px) {
        position: relative;
        top: 0;
    }
}

.form li a {
    // width:225px;
    padding-left:20px;
    padding-right:20px;
    height:50px;
    line-height:50px;
    display:block;
    overflow:hidden;
    position:relative;
    text-decoration:none;
    text-transform:uppercase;
    font-size:14px;
    color:#686868;
    
    -webkit-transition:all 0.2s linear;
    -moz-transition:all 0.2s linear;
    -o-transition:all 0.2s linear;
    transition:all 0.2s linear;			
}

.form li a:hover {
    background:#efefef;
}

.form li a.profile {
    border-left:5px solid $dark;
}

.form li a.my-annonces {
        border-left:5px solid $orange;
}
    
.form li a.favoris {
        border-left:5px solid $blue;
}

.form li a.admin {
    border-left:5px solid $success;
}

.form li a.conversation {
    border-left:5px solid $danger;
}

.form li a.superadmin {
    border-left:5px solid $danger;
}

.form li a.logout {
        border-left:5px solid #dde2d5;
}	

.form li:first-child a:hover, .form li:first-child a {
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}

.form li:last-child a:hover, .form li:last-child a {
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
}

.form li a:hover i {
    color:#ea4f35;
}

.form i {
    margin-right:15px;
    
    -webkit-transition:all 0.2s linear;
    -moz-transition:all 0.2s linear;
    -o-transition:all 0.2s linear;
    transition:all 0.2s linear;	
}

.form em {
    font-size: 10px;
    background: #ea4f35;
    padding: 3px 5px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;		
    font-style: normal;
    color: #fff;
    margin-top: 17px;
    margin-right: 15px;
    line-height: 10px;
    height: 10px;		
    float:right;
}

.form li a.active {
    background:#efefef;
}