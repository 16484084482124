footer {
    background: none;
    color: white;
    width: 100%;
    padding: 10px;
    max-width: 1920px;
    overflow: hidden;
    @media screen and (max-width: 1271px) {
        padding-top: 60px;
    }
    a {
        margin: auto 10px;
        padding: 10px 20px !important;
        border-radius: 20px;
        font-weight: 500;
        color: white;
        font-size: 15px;
        transition: background-color 0.3s ease-in-out;
        word-break: break-word;
        &:hover {
            color: $orange;
            transition: color 0.6s ease-in-out;
        }
        i {
            color: $orange;
            margin-right: 5px;
            &:hover {
                color: white;
                transition: color 0.6s ease-in-out;
            }
        }
    }

    .ms-3 {
        i {
            font-size: 30px;
        }
    }
}

// .footer-top {
//     background-color: $dark;
//     position: fixed;
//     bottom: 0;
//     width: 100%;
//     z-index: 10;
//     height: 20px;
// }