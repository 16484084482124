.pagination {
    justify-content: center;
    overflow: auto !important;
    @media screen and (max-width: 350px) {
        flex-direction: column;
    }
}
.page-link {
    color: white !important;
    background-color: $dark !important;
    border: 0 !important;
    transition: background-color 1s ease-in-out;
    @media screen and (max-width: 350px) {
        border-radius: 0 !important;
    }
    &:hover{
        background-color: $blue !important;
        transition: background-color 0.5s ease-in-out;
    }
}

.page-item.active {
    .page-link {
        color: $dark !important;
        background-color: $orange !important;
    }
}
