// @import url('https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Rubik+Vinyl&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

// @font-face {
//     font-family: 'Modernist';
//     src: 
//         url('./fonts/Modernist-Regular.otf') format('truetype');
//     font-weight: 400;
//     font-style: normal;
//     font-display: swap; /* Utiliser 'swap' pour basculer vers la police dès qu'elle est chargée */
// }

@font-face {
    font-family: 'Montserrat';
    src: 
        url('./fonts/Montserrat.ttf') format('truetype');
    // font-weight: 400;
    font-style: normal;
    font-display: swap; /* Utiliser 'swap' pour basculer vers la police dès qu'elle est chargée */
}

@font-face {
    font-family: 'Roboto';
    src: 
        url('./fonts/Roboto.ttf') format('truetype');
    // font-weight: 00;
    font-style: normal;
    font-display: swap; /* Utiliser 'swap' pour basculer vers la police dès qu'elle est chargée */
}


// @font-face {
//     font-family: 'Rubik';
//     src: 
//         url('./fonts/Rubik-VariableFont.ttf') format('truetype');
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Grandiflora';
//     src: 
//         url('./fonts/GrandifloraOne.ttf') format('truetype');
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'manrope';
//     src: 
//         url('./fonts/manrope.ttf') format('truetype');
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Modernist-Bold';
//     src: 
//         url('./fonts/Modernist-Bold.otf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap; /* Utiliser 'swap' pour basculer vers la police dès qu'elle est chargée */
// }


// @font-face {
//     font-family: 'Quartzo';
//     src: 
//         url('./fonts/Quartzo.ttf') format('truetype');
//     font-style: normal;
// }


.font-loaded {
    font-family: 'Montserrat', sans-serif;
}