.flash-danger, .flash-warning, .flash-success, .alert-danger {
    width: 100%;
    font-weight: 500 !important;
    padding: 10px !important;
    text-align: center;
    border-radius: 20px !important;
    margin: 10px !important;
}

.flash-warning {
    background-color: $dark !important;
}

.flash-success {
    background-color: $success !important;
    color: white !important
}

.flash-danger, .alert-danger {
    background-color: $danger !important;
    color: white !important
}

.help-block {
    color: white;
    font-weight: 700;
    background-color: $danger;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 20px;
    margin-top: 10px;
}