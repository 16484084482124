///Basics
@import "color";
@import "font";
////
@import "mixins";
@import "card";
@import "flash";
@import "nav";
@import "nav-pills";
@import "list-group-item";
@import "sections";
@import "footer";
@import "breadcrumb";
@import "pagination";
@import "event";
@import "~bootstrap/scss/bootstrap";

body {
    font-family: 'Montserrat', sans-serif;
    // font-weight: 400;
    font-size: 18px;
    padding: 25px;
    background-image: url("../../public/images/wallpaper-bkg.webp");
    background-color: $black;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 1920px auto;

    @media screen and (max-width: 1271px) {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 15px;
    }
}

// .sf-toolbar {
//     display: none !important;
//   }

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

p {
    text-align: left;
}

.btn-sm {
    padding: 5px 20px;
}

strong {
    background: radial-gradient(circle at center center, rgb(0, 0, 0) 0%, #000000d3 56%, rgba(0, 0, 0, 0.993) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

img {
    object-fit: cover;
}

::-moz-selection {
    /* Code for Firefox */
    background: rgba($dark, 0.8);
    color: $white;
}

::selection {
    background: rgba($dark, 0.8);
    color: $white;
}

.bg-dark {
    ::-moz-selection {
        /* Code for Firefox */
        background: rgba($orange, 0.3);
    }
    
    ::selection {
        background: rgba($orange, 0.3);
    }
    
}

.container,
.container-fluid {
    border-radius: 0 0 20px 20px;
}

.container-radius {
    border-radius: 20px !important;
    padding: 10px !important;
}

.container-radius--padding {
    border-radius: 20px !important;
    padding: 50px !important;
    @media screen and (max-width: 1271px) {
        padding: 25px !important;
    }
}

hr {
    border: 1px solid $blue;
    width: 50%;
}

.form-control {
    font-weight: 500;
    border: 0;
    box-shadow: 10px 11px 28px 0px $grey;
    background-color: white;
}

.text-sm {
    font-size: 12px;
}

.font-back {
    background: radial-gradient(circle at center center, rgb(0, 0, 0) 0%, #0f0e0e91 56%, rgba(46, 45, 45, 0.767) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    word-break: break-word;
}

.help-block {
    background-color: transparent !important;
    color: $danger !important;
}

.container {
    position: relative;
    max-width: 1300px;
    padding: 0;
    background: rgb(255, 238, 209);
    background-repeat: no-repeat;
    background: linear-gradient(-5deg, rgb(255, 238, 209) 5%, rgb(235, 235, 255) 40%, rgb(241, 241, 241) 60%, rgb(255, 255, 255) 70%);
    z-index: 1;
    overflow: hidden;
    @media screen and (max-width: 1500px) {
        max-width: 1200px;
    }

    .mt-m5 {
        margin-top: -30px;
    }
    &.no-image {
        margin: 50px auto;
        padding: 10px 15px 50px 35px;

        @media screen and (max-width: 1271px) {
            padding: 5px 15px 50px 15px;
        }
    }

    &.padding {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        padding-top: 10px;

        @media screen and (max-width: 1271px) {
            padding: 20px 5px;
        }
    }

    &.padding-light {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        padding-top: 10px;

        @media screen and (max-width: 1271px) {
            padding: 30px;
        }
    }

    &.padding-annonce {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        padding-top: 20px;
        @media screen and (max-width: 1271px) {
            padding: 20px 20px 50px 20px !important;

        }
    }

    // &::before {
    //     display: block;
    //     content: "";
    //     position: absolute;
    //     right: -100px;
    //     bottom: -30px;
    //     transform: translate(-50%, 0%);
    //     z-index: 10;
    //     background-color: rgb(16, 20, 41);
    //     border-radius: 50%;
    //     height: 100px;
    //     width: 100px;
    //     background-image: url("../../public/images/avion.png");
    //     background-size: 70px;
    //     background-position: left;
    //     background-repeat: no-repeat;

    //     @media screen and (max-width: 1271px) {
    //         transform: translate(50%, 0%);
    //         right: 50%;
    //         bottom: -55px;

    //     }

    // }
}

.container.annonces.no-image {
    background: linear-gradient(0deg, rgb(255, 238, 209) 5%, rgb(235, 235, 255) 60%, rgb(255, 255, 255) 80%);
}

.no-bkg {
    background: none;
}
.no-radius {
    border-radius: 0 !important;
}

h1,
h2,
h3,
h4,
h5 {
    // font-family: 'Rubik';
    // font-weight: 900;
    background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h2 span {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

h2 span.is-visible {
    opacity: 1;
}

h1 {
    // opacity: 0;
    font-size: calc(30px + 0.5vw);
}

h3,
h4,
h5,
h6 {
    font-weight: 500;
    // font-family: 'Rubik';
    z-index: 10;
    position: relative;
    // opacity: 0;
}

p { 
    // font-family: 'Rubik';
}

.fade-in {
    opacity: 0;
    /* L'élément est initialement transparent */
    transition: opacity 0.8s ease-in-out;
    /* Ajoute une transition pour un effet de fondu en douceur */
}

.fade-in.visible {
    opacity: 1;
    /* Lorsque l'élément est dans la vue, le rend visible */
}

.italic {
    font-style: italic;
}

.img-top {
    max-width: 100%;
    height: 100vh;
    max-height: 680px;
    background-color: $dark;
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    position: relative;
    border-radius: 30px 30px 0 0;
    margin-top: 50px;
    z-index: 1;
    box-shadow: 0px 110px 14px -4px rgba(0, 0, 0, 0.18);
    @media screen and (max-width: 1200px) {
        margin-top: 45px;
    }
  
}

#wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999999;
}

.p-absolute {
    position: absolute;
    @media screen and (max-width: 1200px) {
        position: static;
    }
}
.p-left {
    left : 0;
}
.p-right {
    right : 0;
}
.btn-bottom {
    position: fixed;
    width: 100%;
    z-index: 999999999999;
    max-width: 1450px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    .btn {
        padding: 15px 30px;
        font-size: 18px;
        border-radius: 0;
        @media screen and (max-width: 1140px) {
            font-size: 16px;
        }
    }
}

.btn-light {
    border-radius: 0;
    border-bottom: 1px solid $black;
}
.space-between {
    justify-content: space-between;
}

.mw-100 {
    max-width: 100px !important;
}

.custom-file-label::after {
    content: attr(data-text);
}

.responsive-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 43.25%;
    /* Ratio 16:9 */
    margin: 20px 0 50px 0;
}

.responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 500px;
}

.video-legend {
    background-color: $dark !important;
    background: rgb(17, 18, 26) !important;
    padding: 15px;
    -webkit-text-fill-color: white !important;
    border-radius: 0 0 20px 20px;
    text-justify: none;
    text-align: center;

    span {
        font-size: 12px;
        -webkit-text-fill-color: $orange;
    }

    a {
        word-break: break-all;
        -webkit-text-fill-color: $clear-blue;
    }
}

.video-container {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 17px 21px 41px -24px $dark;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.video-content {
    position: relative;
    width: 100%;
    height: 500px;
}

.video-content video {
    width: 100%;
    height: 500px;
    object-fit: cover;
    background-color: $dark;
    border-radius: 20px 20px 0 0;
    // padding-top: 20px;
}

.fancybox__container {
    z-index: 999999999999999999999999 !important;
    --fancybox-bg: rgba(17, 18, 26, 0.993) !important
}

.btn-warning {
    background-color: $orange;
    color: $dark;
    font-weight: 600;
    border: 0;
    padding: 10px 30px;

    i {
        color: $dark;
    }
}

.btn-primary {
    background-color: $dark;
    color: white;
    font-weight: 600;
    border: 0;
    padding: 10px 30px;

    i {
        color: $orange;
    }
}

p {
    // font-weight: 500;
    // opacity: 0;
}

.border-primary {
    font-size: 15px;
    color: $dark;

    i {
        color: $blue;
    }
}

.feedback-text {
    opacity: 1;
    max-height: 100px;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
    i {
        margin-right: 0;
    } 
}
  
  .feedback-text.hide {
    opacity: 0;
    max-height: 0;
  }
  
  #password-length-feedback {
    color: $danger;
  }
  
  #password-match-feedback {
    color: $danger;
  }
  
  #password-success-feedback {
    color: $success;
  }

.sortable {
    .fa-sort-up {
        position: absolute;
        left: -22px;
        top: 42%;
    }

    .fa-sort-down {
        position: absolute;
        left: -22px;
        top: 10%;
    }

    .fa-sort {
        position: absolute;
        left: -22px;
        top: 10%;
    }
}

#videoYoutube {
    border-radius: 20px;
    // border: 5px solid $dark-blue;
    // box-shadow: 0px 5px 15px 0px $dark;
    margin-top: 20px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    align-items: center;

    @media screen and (max-width: 1500px) {
        grid-template-columns: repeat(3, 1fr);
        margin-top: 20px;
    }

    @media screen and (max-width: 860px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.grid--account {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    align-items: center;

    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 20px;
    }

    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
    }

}

.lazyfadein {
    opacity: 0;
    transition: opacity 0.5s;
}

.lazyfadein.lazyloaded {
    opacity: 1;
    background-color: $dark;
    img {
        visibility: visible !important;
        transition: all 0.5s !important;
    }
}


.toggle {
    font-weight: 500;
    color: $dark;
    cursor: pointer;

    i {
        color: $blue;
    }
}

.carousel-control-prev,
.carousel-control-next {
    z-index: 1000;
    color: white;
    font-size: 30px;
}

.lightwidget__lightbox--active {
    z-index: 99999999999999 !important;
}