.homepage {
    padding: 0;
    position: relative;

    .img-top {
        position: relative;
        z-index: -1;
        max-height: 700px;

        @media screen and (max-width: 1140px) {
            height: 530px;
        }

        @media screen and (max-width: 800px) {
            height: 470px;
        }
        @media screen and (max-width: 600px) {
            height: 350px;
        } 
        
    .title {
        position: absolute;
        left: 50%;
        bottom: 8%;
        transform: translate(-50%, 0%);
        text-align: center;
        font-size: 40px;
        width: 100%;
        background: radial-gradient(circle at center center, rgb(244 168 135) 0%, rgb(92 92 92) 56%, rgb(255, 255, 255) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
            @media screen and (max-width: 740px) {
                font-size: 30px;
                background: radial-gradient(circle at center center, rgb(244 168 135) 0%, rgb(92 92 92) 56%, rgb(255, 255, 255) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                bottom: 5%;
            }
            @media screen and (max-width: 480px) {
                font-size: 20px;
            }
            @media screen and (max-width: 300px) {
                font-size: 16px;
            }
        }  
    }

    .h2-position {
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    h2 {
        font-size: 20px;

        @media screen and (max-width: 740px) {
            font-size: 16px;
        }
    }


    h1 {
        font-size: 30px;

        @media screen and (max-width: 740px) {
            font-size: 22px;
        }
    }

    p.text-center, h3.text-center, h2.text-center, h1.text-center {
        @media screen and (max-width: 740px) {
            text-align: left !important;
        }
    }

    h3 {
        font-size: 25px;
        @media screen and (max-width: 740px) {
            font-size: 20px;
        }
    }

    h4 {
        font-size: 20px;
    }

    h5 {
        font-size: 25px;
    }

    .img-after {
        right: 0;
        left: unset;
    }

    .all-cards {
        .card {
            margin: 0 10px;
        }
    }
    .row.bgDark {
        margin: -5px;
    }
    .bgDark {
        padding: 30px 0;
        border-radius: 33% 33% 0 0;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            background-color: $dark;
            opacity: 0.75;
            height: 100%;
            width: 100%;
            z-index: 0;
            border-radius: 33% 33% 0 0;
        }

        @media screen and (max-width: 1400px) {
            border-radius: 0;

            &::before {
                border-radius: 0;
            }
        }

    }
}

.login,
.register,
.blog {
    h1,h2 {
        text-align: center;
        font-weight: 600;
        font-size: calc(25px + 0.5vw);
        background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 auto 10px;
    }

    h3, h4 {
        text-align: center;
        font-weight: 600;
        font-size: calc(20px + 0.5vw);
        background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 auto 10px;
    }

    .form {
        width: 60%;
        padding: 30px;
        margin: auto;
        border: 3px dashed $grey;

        @media screen and (max-width: 1140px) {
            width: 90%;

        }
    }
}

.register {
    .img-top {
        background-position: right top;
        max-height: 500px;

        @media screen and (max-width: 740px) {
            height: 300px;
        }
    }
}

.login {
    .img-top {
        background-position: center;
        max-height: 500px;

        @media screen and (max-width: 740px) {
            height: 300px;
        }
    }

    // padding-top: 100px !important;
}

.blog {
    .img-top {
        position: relative;
        z-index: -1;
        max-height: 400px;

        @media screen and (max-width: 740px) {
            height: 300px;
        }
    }

    hr {
        border: 1px solid white;
    }

    h1 {
        font-size: calc(30px + 0.5vw);
    }

    p {
        background: radial-gradient(circle at center center, rgb(0, 0, 0) 0%, #0f0e0e91 56%, rgba(46, 45, 45, 0.767) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .card-text {
        background: radial-gradient(circle at center center, rgb(211, 195, 195) 0%, #ffffff91 56%, rgba(112, 105, 105, 0.767) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h2 {
        text-align: center;
        font-weight: 600;
        font-size: calc(20px + 0.5vw);
        background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 auto 10px;
    }

    .card-title {
        text-align: center;
        font-weight: 600;
        font-size: calc(20px + 0.5vw);
        background: radial-gradient(circle at center center, rgb(255, 255, 255) 0%, #d4c8c891 56%, rgba(255, 255, 255, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
    }

    h4,
    h5 {
        font-size: 20px;
    }

    i {
        color: $dark;
    }

    img {
        border-radius: 20px;
    }

    .form-group img {
        max-width: 150px;
    }
}

.annonces {
    margin-top: 52px;
    .filter {
        .form-control {
            border: 0;
            box-shadow: 10px 11px 28px 0px $grey;
            text-align: center;
        }
        select  {
            overflow: hidden;
            width: 100%;
            background-color: rgb(250, 250, 250) !important;
            box-shadow: 10px 11px 28px 0px $grey;
        }
    }

    .title {
        font-weight: 600;
        font-size: calc(20px + 0.5vw);
        background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media screen and (max-width: 1500px) {
            font-size: calc(15px + 0.5vw);

        }
        
    }

    .img-top {
        position: relative;
        z-index: 0;
        max-height: 440px;
        box-shadow: none;
        margin-top: 0;
        @media screen and (max-width: 767px) {
            height: 185px;
        }

    }

    h2 {
        font-size: calc(14px + 0.5vw);
        background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h3,
    h4,
    h5,
    h6 {
        font-size: 17px;
        background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .description {
        font-weight: 400;
        font-size: 18px;
        background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    label {
        font-size: 15px;
        background: radial-gradient(circle at center center, rgb(0, 0, 0) 0%, #0f0e0e91 56%, rgba(46, 45, 45, 0.767) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    #submit-btn {
        @media screen and (max-width: 1500px) {
            font-size: calc(10px + 0.5vw);
            padding: 14px 14px;
        }
    }
}

.annonce-form {
    h1, h2, h3 {
        font-size: 30px;
        font-weight: 900;
    }
    .error {
        box-shadow: 0px 4px 6px -3px $danger !important; /* Vous pouvez personnaliser la mise en surbrillance ici */
    }
    label,
    .add-pics {
        font-size: 18px;
    }

    .form-control {
        font-weight: 500;
        border-bottom: 1px solid $grey;
        box-shadow: 10px 11px 28px 0px $grey;
        background-color: rgb(255, 255, 255);
    }
    select  {
        overflow: hidden;
        width: 100%;
        background-color: rgb(250, 250, 250) !important;
        box-shadow: 10px 11px 28px 0px $grey;
    }

    .hidden-form {
        display: none;
    }    

    img {
        border: 1px solid white;
        object-fit: cover;
        max-width: 200px;
        max-height: 200px;
        @media screen and (max-width: 400px) {
            max-width: 120px;
            max-height: 120px;
        }
    }
    .no-border {
        border: 0 !important;
    }
    .preview-image {
        max-width: 200px;
        margin-top: 20px;
        background-size: contain;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        @media screen and (max-width: 400px) {
            max-width: 120px;
        }
    }

    .boost-prime {
        position: relative;

        &::before {
            display: block;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f005";
            position: absolute;
            left: 50%;
            bottom: -20px;
            transform: translate(-50%, 0%);
            padding: 5px 12px;
            border-radius: 10px;
            color: $orange;
            background-color: $dark;
        }
    }

    .text-justify {
        padding: 20px 10px;
    }
}

.annonce {
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;

    h1 {
        font-size: 30px;
        font-weight: 600;
        @media screen and (max-width: 900px) {
            font-size: 25px;
        }
        @media screen and (max-width: 768px) {
            font-size: 20px;
            font-weight: 500;
        }
    }

    h2 {
        font-size: 25px;
        @media screen and (max-width: 900px) {
            font-size: 20px;
        }
        @media screen and (max-width: 768px) {
            font-size: 15px;
        }
    }

    .titre {
        font-weight: 600;
        font-size: calc(25px + 0.5vw);
        background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h3,
    h4 {
        background: radial-gradient(#08080891 66%, rgba(0, 0, 0, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        @media screen and (max-width: 900px) {
            font-size: 15px;
        }
        // @media screen and (max-width: 768px) {
        //     font-size: 15px;
        // }
    }
    .annonce-nopicture {
        padding-top: 50px;
        margin-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        border-radius: 30px;
        @media screen and (max-width: 1271px) {
            padding: 50px 10px 10px 20px !important;
        }
    }
    .badge {
        padding: 10px 20px;
        margin: 5px;
    }

    .img-top {
        position: relative;
        max-width: 100%;
        height: 130%;
        padding-top: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        cursor: pointer;
        @media screen and (max-width: 768px) {
            padding-top: 0;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            background-color: $dark;
            opacity: 0.9;
            height: 100%;
            width: 100%;
            z-index: 0;
        }

        svg {
            z-index: 20;
        }

        .gallery {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        .img-annonce {
            position: relative;
            margin-left: 5px;
            margin-right: 5px;
            z-index: 10;
            width: calc(33.33% - 20px);
            height: 450px;
            padding-bottom: 33.33%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            img {
                height: 450px;
                width: 100%;
                @media screen and (max-width: 720px) {
                    height: 350px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            .img-annonce {
                width: 100%;
                padding-bottom: 50%;
                margin: 0;
                height: 300px;
            }
        }
    }
}

.annonce-boosted {
    max-width: 1800px;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 45px 10px !important;

    .bg-white {
        padding: 50px;

        @media screen and (max-width: 1140px) {
            padding: 20px;
        }
    }

    h1 {
        font-weight: 600;
        font-size: 35px;
        @media screen and (max-width: 900px) {
            font-size: 25px;
            font-weight: 500;
        }
        // @media screen and (max-width: 768px) {
        //     font-size: 20px;
        // }
    }

    h2 {
        font-size: 25px;
        @media screen and (max-width: 900px) {
            font-size: 20px;
        }
        @media screen and (max-width: 768px) {
            font-size: 15px;
        }
    }

    .titre {
        font-weight: 600;
        font-size: calc(20px + 0.5vw);
        background: radial-gradient(circle at center center, rgb(51, 49, 49) 0%, #53373791 56%, rgba(59, 58, 58, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h3,
    h4 {
        background: radial-gradient(#08080891 66%, rgba(0, 0, 0, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        @media screen and (max-width: 900px) {
            font-size: 15px;
        }
    }

    &::before {
        position: fixed;
        left: 0;
        top: 0;
        content: "";
        background-color: $dark;
        opacity: 0.9;
        height: 100%;
        width: 100%;
        z-index: 0;
    }

    .image-boosted {
        background-size: cover;
        background-repeat: no-repeat;
        margin: 10px 0 !important;
        overflow: hidden;
        padding: 0;
        width: 100%;
        background-position: center center;
        img {
            height: 100%;
            max-height: 450px;
            width: 100%;
        }

        @media screen and (max-width: 740px) {
            height: 300px;
        }
    }
}

.CGU {
    ul {
        list-style-type: none;
        padding-left: 0;
    }

    .titre {
        color: $dark;
        font-weight: 700;
    }

    h1,
    h2 {
        font-size: 25px;
        text-align: center;
        margin: 20px;
    }
}

.merci {
    h1 {
        font-size: calc(40px + 0.5vw);
    }

    .success-checkmark {
        width: 80px;
        height: 115px;
        margin: 0 auto;

        .check-icon {
            width: 80px;
            height: 80px;
            position: relative;
            border-radius: 50%;
            box-sizing: content-box;
            border: 4px solid $success;

            &::before {
                top: 3px;
                left: -2px;
                width: 30px;
                transform-origin: 100% 50%;
                border-radius: 100px 0 0 100px;
            }

            &::after {
                top: 0;
                left: 30px;
                width: 60px;
                transform-origin: 0 50%;
                border-radius: 0 100px 100px 0;
                animation: rotate-circle 4.25s ease-in;
            }

            &::before,
            &::after {
                content: '';
                height: 100px;
                position: absolute;
                background: transparent;
                transform: rotate(-45deg);
            }

            .icon-line {
                height: 5px;
                background-color: $success;
                display: block;
                border-radius: 2px;
                position: absolute;
                z-index: 10;

                &.line-tip {
                    top: 46px;
                    left: 14px;
                    width: 25px;
                    transform: rotate(45deg);
                    animation: icon-line-tip 0.75s;
                }

                &.line-long {
                    top: 38px;
                    right: 8px;
                    width: 47px;
                    transform: rotate(-45deg);
                    animation: icon-line-long 0.75s;
                }
            }

            .icon-circle {
                top: -4px;
                left: -4px;
                z-index: 10;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                position: absolute;
                box-sizing: content-box;
                border: 4px solid rgba(76, 175, 80, .5);
            }

            .icon-fix {
                top: 8px;
                width: 5px;
                left: 26px;
                z-index: 1;
                height: 85px;
                position: absolute;
                transform: rotate(-45deg);
                background-color: transparent;
            }
        }
    }
}

.compte {
    .contact-annonce {
        display: block;
        position: sticky;
        top: 100px;
    }

    .contact-message {
        padding: 20px 20px;
        border-radius: 20px;
        margin: 10px;
        text-align: left;
        background: $dark-blue;
        color: white;
        strong {
            background: none;
            -webkit-text-fill-color: white !important;
            color: white !important;
        }
    }

    .contact-color {
            background: $grey;
            color: $black;
            text-align: right;
            strong {
                background: none;
                -webkit-text-fill-color: $black !important;
                color: $black !important;
            }
    }
}