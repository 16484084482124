.list-groups {
    .list-group-item {
        border-radius: 20px !important;
        text-decoration: none;
        color: $dark;
        font-weight: 500;
        transition: background-color 0.5s ease-in-out;
        &:hover {
            text-decoration: none;
            background-color: $grey !important;
            transition: background-color 0.5s ease-in-out;
            color: $dark !important;
        }
    }
}