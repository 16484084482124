$dark:       rgb(17, 18, 26);
$dark-blue:  rgb(49, 51, 76);
$black:      #000;
$danger:     rgb(193, 51, 51);
$grey:       #eee;
$grey-2:     #bebebe;
$clear-blue: rgb(102, 119, 152);
$blue:       #314e6c;
$orange:     rgb(248, 185, 82);
$boost:      rgba(0, 0, 0, 0.685);
$success:    rgb(36, 128, 76);