.navbar {
    max-width: 1920px;
    font-family: 'Roboto';
    margin: auto !important;
    padding: 35px 0 40px !important;
    background: linear-gradient(0deg, rgb(255 107 107 / 0%) 0%, rgba(0, 0, 0, 0.57) 72%, rgb(8 6 6 / 71%) 100%);
    @media screen and (max-width: 1270px) {
        padding: 15px 0 25px !important;
    }
    @media screen and (max-width: 1200px) {
        padding: 6px 0 0 !important;
    }
    @media screen and (max-width: 1199px) {
        padding: 0 !important;
    }

    .logo-container {
        perspective: 1000px;
        width: 60px; /* Ajustez la largeur à celle de votre logo */
        height: 60px; /* Ajustez la hauteur à celle de votre logo */
      }
      
      .logo {
        width: 100%;
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        animation: logoAnimation 2s infinite linear;
      }
      

    .navbar-brand {
        // font-family: 'Rubik';
        font-size: 16px;
        letter-spacing: 1px;
        background: radial-gradient(circle at center center, rgb(191 186 179) 0%, rgb(255 244 229) 56%, rgba(146, 142, 142, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: color 0.6s ease-in-out;
        font-weight: 600;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1199px) {
            position: absolute;
            left: 50%;
            top: 30px;
            transform: translate(-50%, -50%);
        }
        @media screen and (max-width: 420px) {
            left: 125px;
            top: 31px;
            font-size: 15px;
            font-weight: 500;
        }

        &:hover {
            transition: color 0.6s ease-in-out;
        }
    }

    .navbar-toggler {
        font-size: 17px;
        padding: 0.25rem 0.75rem !important;
        background: radial-gradient(circle at center center, #ffffff91 0%, white 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        i {
            background: radial-gradient(circle at center center, $orange 100%, $orange 5%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &:hover {
            background: radial-gradient(circle at center center, white 100%, $orange 5%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .nav-link {
        font-weight: 500;
        padding: 10px 20px !important;
        border-radius: 0;
        letter-spacing: 1px;
        font-size: 16px;
        background: radial-gradient(circle at center center, rgb(219, 213, 213) 0%, #ffffffc9 56%, rgba(146, 142, 142, 0.478) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: opacity 0.3s ease-in-out, background 0.3s ease-in-out;
        @media screen and (max-width: 1535px) {
            font-size: 13px;
            padding: 10px 15px !important;
        }
        &:hover {
            background: radial-gradient(circle at center center, white 100%, $orange 5%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: background 0.3s ease-in-out;
        }

        .login {
            color: $orange !important;
        }

        .text-muted {
            font-size: 12px;
            color: white !important;
        }
    }

    .nav-item {
        color: white;
    }

    .nav-item.active {
        .nav-link {
            font-weight: 600;
            background: radial-gradient(circle at center center, white 0, $orange 0) !important;
            -webkit-background-clip: text !important;
            -webkit-text-fill-color: transparent !important;
        }
    }

    [class*="col"] {
        padding: 0;
    }

    @media screen and (max-width: 1271px) {
        .float-right {
            float: none !important;
        }
    }
    @media screen and (max-width: 1200px) {
        .navbar-collapse.show, .navbar-collapse.collapsing {
            text-align: center;
            transition: height 0s ease;
            background: #1c2735;
        }
        
        .navbar-collapse.show {
            height: calc(100vh - 56px);
            transition: height .35s ease;
            .nav-link {
                margin: 10px;
                font-size: 16px;
                background: radial-gradient(circle at center center, rgb(219, 213, 213) 0%, #ffffff91 56%, rgba(146, 142, 142, 0.478) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                &:hover {
                    background: radial-gradient(circle at center center, white 100%, $orange 5%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }   
            }
        }
    }
}

.nav-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 1271px) {
        position: static;
        transform: none;
    }
}

.navbar-scrolled {
    background: linear-gradient(0deg, rgb(227 198 198 / 0%) 0%, rgb(17 17 18) 10%, rgb(0 0 0 / 89%) 100%);
    @media screen and (max-width: 1199px) {
        background: #1c2735;
        box-shadow: -1px 9px 20px #00000069;
    }
}